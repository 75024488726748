<template>
    <div class="pt-6">
        <v-container fluid>
            <v-row>
                <v-col cols="12"> <h1 class="text-h4">Ny sorggrupppe</h1></v-col>
                <v-col cols="12">
                    <v-form ref="form" v-model="valid" lazy-validation style="max-width: 800px">
                        <v-row>
                            <v-col cols="12" sm="4">
                                <v-select v-model="form.lopenr" :items="ledigeLopenr" :rules="rules.lopeNr" label="Nr"></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select v-model="form.typeId" :items="typer" item-value="id" item-text="navn" :rules="rules.typeId" label="Type"></v-select>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-select
                                    v-model="form.metodeId"
                                    :items="metoder"
                                    item-value="id"
                                    item-text="navn"
                                    :rules="rules.metodeId"
                                    label="Metode"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <br />
                        <v-btn color="primary" @click="create">
                            {{ $t('btn.create') }}
                        </v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { /*mapGetters,*/ mapActions } from 'vuex';
//import moment from 'moment';

export default {
    name: 'GriefNewGroup',

    data() {
        return {
            valid: true,

            form: {
                typeId: '',
                metodeId: '',
            },

            ledigeLopenr: [],
            typer: [],
            metoder: [],

            rules: {
                lopeNr: [(v) => !!v || 'Nummer må velges'],
                typeId: [(v) => !!v || 'Type må velges'],
                metodeId: [(v) => !!v || 'Metode må velges'],
            },
        };
    },

    created: async function () {
        let response = await this.request({
            method: 'get',
            url: '/sorggruppe/type',
        });
        if (response && Array.isArray(response)) {
            this.typer = response;
        }

        response = await this.request({
            method: 'get',
            url: '/sorggruppe/metode',
        });
        if (response && Array.isArray(response)) {
            this.metoder = response;
        }

        response = await this.request({
            method: 'get',
            url: '/sorggruppe',
            params: {
                bareAktive: true,
            },
        });
        if (response && Array.isArray(response)) {
            const lopenr = [];
            this.grupper = [];
            for (const gruppe of response) {
                lopenr[gruppe.lopenr] = gruppe.lopenr;
            }
            const ledigeLopenr = [];
            for (let index = 1; index < lopenr.length; index++) {
                if (!lopenr[index]) {
                    ledigeLopenr.push(index);
                }
            }
            ledigeLopenr.push(lopenr[lopenr.length - 1] + 1);
            this.ledigeLopenr = ledigeLopenr;
        }
    },
    methods: {
        ...mapActions('api', ['request']),
        ...mapActions(['snackbar']),

        create: async function () {
            if (this.$refs.form.validate()) {
                const response = await this.request({
                    method: 'post',
                    url: '/sorggruppe',
                    data: this.form,
                });

                if (response && typeof response.id != 'undefined') {
                    this.snackbar('Sorggruppe opprettet');
                    this.$router.push({ name: '/sorg/gruppe', params: { id: response.id } });
                }
            }
        },
    },
};
</script>
<style lang="scss"></style>
